import { LocalstorageStore } from '@cj4/store-localstorage'
import { useCallback, useMemo, useRef, useState } from 'react'
import useSWR from 'swr'

import { useAppConfig } from '@ngb-frontend/shared/context'
import { BookingStatus } from '@ngb-frontend/shared/types'

import { servicesByServiceCode } from '../service/servicesByServiceCode'

import type { Store } from '@cj4/store'
import type {
  Booking,
  BookingServiceCode,
  CountryCode,
  VehicleIdentifier,
} from '@ngb-frontend/shared/types'

export const filterHistoryBySrvCode = (
  serviceCode: BookingServiceCode,
  history?: Booking[],
) => {
  return history?.filter(
    (his) =>
      his.service?.items?.some((i) =>
        servicesByServiceCode[serviceCode].includes(i.code),
      ) &&
      (his.status === BookingStatus.Confirmed ||
        his.status === BookingStatus.Unconfirmed),
  )
}

export const useBookingRequestHistory = (
  vehicleIdentifier?: VehicleIdentifier,
  serviceCode?: BookingServiceCode,
  countryCode?: CountryCode,
) => {
  const config = useAppConfig()
  const store = useRef<Store<boolean>>(
    new LocalstorageStore(config.localStorageKeys.bookingHistoryChecked),
  )

  const [hasBeenChecked, setHasBeenChecked] = useState(!!store.current.get())
  const res = useSWR<Booking[]>(
    vehicleIdentifier && countryCode
      ? [
          config.apiRoutes.fetchBookingHistory,
          { vehicleIdentifier: vehicleIdentifier, countryCode },
        ]
      : null,
  )

  const markAsChecked = useCallback(() => {
    store.current.set(true)
    setHasBeenChecked(true)
  }, [])

  const filteredData = useMemo(() => {
    return serviceCode
      ? filterHistoryBySrvCode(serviceCode, res.data)
      : res.data
  }, [res.data, serviceCode])

  const hasData = !!filteredData?.length

  return { ...res, markAsChecked, hasBeenChecked, hasData, data: filteredData }
}
